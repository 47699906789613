import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion as BootstrapAccordion, AccordionContext, Card, useAccordionToggle
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { unique } from '@core/helpers/arrays';

import { Graphql } from '@models/graphql';

import './styles.scss';

type AccordionProps = {
  areas: Graphql[];
  jobs: Graphql[];
  title: string;
  subtitle: string;
};

type AccordionContent = {
  children: unknown;
  eventKey: string;
  callback: Function | null;
};

export default function JobsArea({
  areas, jobs: currentJobs, title, subtitle
}: AccordionProps): React.ReactElement {
  const { t } = useTranslation();
  const areasJobs = unique(currentJobs?.map(({ node }) => node?.relationships?.department?.name)
    .flat() as Graphql[]);

  const activeJobsArea = areasJobs.map((area) => (
    {
      area,
      quantity: currentJobs.reduce((quantity, { node }) => {
        if (node?.relationships?.department?.name === area as unknown as string) {
          return parseInt(`${node.quantity}`, 10) + quantity;
        }

        return quantity;
      }, 0)
    }
  ));

  let jobs = activeJobsArea.map(({ area }) => {
    const jobsByArea = currentJobs
      .filter((job) => job.node?.relationships?.department?.name === area as unknown as string)
      .map(({ node }) => node);

    return {
      name: area,
      jobs: jobsByArea
    };
  }).flat();

  jobs = jobs.filter((area) => !!area?.jobs && area.jobs.length > 0 && typeof area.name !== 'undefined');

  if (!jobs || jobs.length === 0) {
    return <></>;
  }

  return (
    <div className="mb-5">
      {title && (
        <h2>
          {title}
        </h2>
      )}
      {subtitle && (
        <p className="text-secondary font-weight-light my-4">
          {subtitle}
        </p>
      )}
      <BootstrapAccordion>
        {(jobs as []).map((area: Graphql) => (
          <Card key={area.name}>
            <Card.Header className="bg-white p-0">
              <ContextAwareToggle
                eventKey={area.name || `${t('No area')}`}
                callback={null}
              >
                <span>{area.name || `${t('No area')}`}</span>
                <span className="font-weight-bold text-nice-blue">{` (${area?.jobs?.length})`}</span>
              </ContextAwareToggle>
            </Card.Header>
            <BootstrapAccordion.Collapse
              eventKey={area.name || `${t('No area')}`}
            >
              <Card.Body className="pt-2">
                <div className="d-flex flex-wrap justify-content-start w-100">
                  {area?.jobs?.map((job) => (
                    <div key={job.id} className="d-flex align-items-center w-100">
                      <div className="d-flex align-items-center mb-3" key={`${job.title}`}>
                        <span className="mr-2">
                          {`${job.title} `}
                        </span>
                        <span className="font-weight-bold text-nice-blue">
                          (
                          {job.quantity}
                          )
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </Card.Body>
            </BootstrapAccordion.Collapse>
          </Card>
        ))}
      </BootstrapAccordion>
    </div>
  );
}

function ContextAwareToggle({ children, eventKey, callback }: AccordionContent) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <BootstrapAccordion.Toggle as={Card.Header} variant="link" eventKey={(children as string)} className="bg-white border-0 cursor-pointer" onClick={decoratedOnClick}>
      <div className="d-flex align-items-center justify-content-between">
        <span className="m-0 text-dark-indigo lead">{children as string}</span>
        <span className={!isCurrentEventKey ? 'text-orange' : ''}>
          <FontAwesomeIcon
            icon={faPlusSquare as IconProp}
            size="lg"
          />
        </span>
      </div>
    </BootstrapAccordion.Toggle>
  );
}
