import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import DomPurify from '@core/helpers/dom-purify';
import MegaMenuCompanyQuery from '@core/layout/navigation-bar/mega-menu/items/company/default-query';
import ImageProvider from '@core/providers/image/image-provider';

import { MenuLink } from '@models/menu-link';
import { Graphql } from '@models/graphql';

import './styles.scss';

type LinkItemProps = {
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function RecognitionLinkItem({
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  return megaMenuStyle === 'modern' ? (
    <ModernRecognitionLinkItem />
  ) : (
    <DefaultRecognitionLinkItem menuLink={menuLink} />
  );
}

function ModernRecognitionLinkItem(): ReactElement {
  const { awards } = MegaMenuCompanyQuery();
  const { t } = useTranslation();
  return (
    <div className="mt-2">
      <p className="text-sm font-weight-normal text-secondary">
        {t('Mega Menu Recognition overview')}
      </p>
      <div className="mt-3 mega-menu-awards d-flex flex-wrap background-light-blue-two p-2 align-items-center justify-content-center">
        {awards.edges.map(({ node }: Graphql) => (
          <div
            key={node?.id}
            className="awards-images col-5 border rounded p-2 background-white m-2 d-flex align-items-center justify-content-center"
          >
            <ImageProvider
              fileName={node?.relationships?.image?.name || ''}
              alt={node?.image?.alt || ''}
              title={node?.title || ''}
              fluid
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function DefaultRecognitionLinkItem({ menuLink }: LinkItemProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="py-4">
      <p className="font-weight-normal text-secondary">
        {t('Mega Menu Recognition overview')}
      </p>
      <div className="row company-content">
        <div className="col">
          <DomPurify text={t('Mega Menu Recognition Content')} />
        </div>
        <div className="d-flex align-items-end align-bottom w-25">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left"
            to={menuLink?.link?.alias || ''}
          >
            {t('Mega Menu Recognition Link')}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}
