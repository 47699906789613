import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import MegaMenuCompanyQuery from '@core/layout/navigation-bar/mega-menu/items/company/default-query';
import GatsbyImageProvider from '@core/providers/image/gatsby-image-provider';
import translateContent from '@core/helpers/translation';

import { MenuLink } from '@models/menu-link';
import { Graphql } from '@models/graphql';

import './styles.scss';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function PartnershipsLinkItem({
  machineName,
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  return megaMenuStyle === 'modern' ? (
    <ModernPartnershipsLinkItem machineName={machineName} menuLink={menuLink} />
  ) : (
    <DefaultPartnershipsLinkItem
      machineName={machineName}
      menuLink={menuLink}
    />
  );
}

function ModernPartnershipsLinkItem({
  machineName
}: LinkItemProps): ReactElement {
  const { partnerships } = MegaMenuCompanyQuery();
  const { t, i18n } = useTranslation();
  const items = translateContent(partnerships.edges ?? [], i18n.language);

  return (
    <div className="mega-menu-item mt-2">
      <div className="mb-3">
        <p className="text-sm font-weight-normal text-secondary">
          {t(`Mega Menu ${machineName} overview`)}
        </p>
      </div>
      <div className="mega-menu-partnership-clients w-100">
        {items.map(({ node }: Graphql) => (
          <div key={node?.id}>
            <GatsbyImageProvider
              nodeImage={node?.relationships?.image}
              altImage={node?.relationships?.image?.attributes?.alt || ''}
              className="img p-1 m-1"
              enableGatsbyImage={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function DefaultPartnershipsLinkItem({
  machineName,
  menuLink
}: LinkItemProps): ReactElement {
  const { partnerships } = MegaMenuCompanyQuery();
  const { t, i18n } = useTranslation();
  const items = translateContent(partnerships.edges ?? [], i18n.language);

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="row mx-0">
            {items.map(({ node }: Graphql) => (
              <div className="col-4 mb-3 px-4" key={node?.id}>
                <GatsbyImageProvider
                  nodeImage={node?.relationships?.image}
                  altImage={node?.relationships?.image?.attributes?.alt || ''}
                  className="company-thumbnail w-100 object-fit-contain position-relative"
                  enableGatsbyImage={false}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end align-bottom">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left pl-4"
            to={menuLink?.link?.alias || ''}
          >
            {t('See more link')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}
