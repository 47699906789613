import React, { useEffect, useState } from 'react';
import {
  Navbar, Nav, NavDropdown, ListGroup
} from 'react-bootstrap';
import { Link as GatsbyLink } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faPlay } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import ContentComponent from '@core/layout/navigation-bar/mega-menu/content-helpers';
import { useSearch } from '@core/providers/search/search-provider';
import SearchAutocomplete from '@core/layout/navigation-bar/mega-menu/search-autocomplete';

import { MenuLink } from '@models/menu-link';
import { LocationModel } from '@models/location';

import './desktop-menu.scss';

type DesktopMenuProps = {
  menu: MenuLink[];
  location: LocationModel
};

export default function DesktopMenu({
  menu, location
}: DesktopMenuProps): React.ReactElement {
  const [contentMenu, setContentMenu] = useState<MenuLink|null>();
  const [indexActive, setIndexActive] = useState<number>(-1);

  useEffect(() => {
    setIndexActive(-1);
  }, [location.pathname]);

  const getMenuLink = (link: MenuLink, index: number) => {
    const onMouseEventListener = (isActive: boolean, newIndexActive: number) => {
      if (isActive && link.children) {
        setContentMenu(link.children[0]);
      } else {
        setContentMenu(null);
      }

      setIndexActive(newIndexActive);
    };

    if (link.children && link.children.length <= 0) {
      return (
        <GatsbyLink
          key={`${link.drupalId}`}
          className={`nav-link nav-item small d-flex align-items-center ${
            indexActive === index ? 'active' : ''
          } ${indexActive} ${index}`}
          to={link?.link?.alias || ''}
          onMouseEnter={() => onMouseEventListener(true, index)}
          onMouseLeave={() => onMouseEventListener(false, -1)}
        >
          {link.title}
        </GatsbyLink>
      );
    }

    return (
      <NavDropdown
        onClick={() => {
          if (link?.children && link.children.length > 0) {
            setContentMenu(link.children[0]);
          }
        }}
        className={`nav-item dropdown small d-flex align-items-center ${
          indexActive === index ? 'active' : ''
        } ${indexActive} ${index}`}
        key={`${link.drupalId}`}
        title={link.title}
        id={`dropdown-${link.drupalId}`}
        onMouseEnter={() => onMouseEventListener(true, index)}
        onMouseLeave={() => onMouseEventListener(false, -1)}
        show={indexActive === index}
      >
        <div className="container">
          <div className="row my-4 w-100">
            <div className="col-3 pr-5">
              <h6 className="mega-menu-title mb-3">{link.title}</h6>
              <ListGroup variant="flush" className="mega-menu-group">
                {link?.children?.map((child) => (
                  <div
                    key={`link-${child.title}`}
                    className={`mega-menu-link position-relative ${
                      contentMenu && contentMenu.drupalId === child.drupalId
                        ? 'active'
                        : ''
                    }`}
                    onMouseEnter={() => setContentMenu(child)}
                  >
                    <GatsbyLink
                      className="nav-link small ml-2 text-sm-left text-marine"
                      to={child?.link?.alias || ''}
                      onClick={() => onMouseEventListener(false, -1)}
                    >
                      {child.title}
                    </GatsbyLink>
                    {contentMenu && contentMenu.drupalId === child.drupalId && (
                      <div className="position-absolute mega-menu-link-arrow d-flex align-items-center">
                        <FontAwesomeIcon icon={faPlay as IconProp} />
                      </div>
                    )}
                  </div>
                ))}
              </ListGroup>
              <div className="position-absolute mega-menu-divider" />
            </div>
            <div className="col-9">
              {contentMenu && (
                <ContentComponent
                  machineName={contentMenu?.title || ''}
                  menuLink={contentMenu}
                />
              )}
            </div>
          </div>
        </div>
      </NavDropdown>
    );
  };

  if (menu.length <= 0) {
    return <></>;
  }

  return (
    <div className="desktop-menu d-flex align-items-center position-relative">
      <Navbar className="ml-auto navbar-menu">
        <Nav>{menu.map(getMenuLink)}</Nav>
      </Navbar>
      <SearchBar />
    </div>
  );
}

function SearchBar(): React.ReactElement {
  const { showSearch, setShowSearch } = useSearch();

  return (
    <>
      <span className="search-icon cursor-pointer">
        <FontAwesomeIcon
          icon={(showSearch ? faTimes : faSearch) as IconProp}
          onClick={() => setShowSearch(!showSearch)}
        />
      </span>
      {showSearch && <SearchAutocomplete limit={8} />}
    </>
  );
}
