import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';

import translateContent from '@core/helpers/translation';
import { isBrowser } from '@core/helpers/ssr';
import { slug } from '@core/helpers/strings';
import VideoItem from '@core/layout/navigation-bar/mega-menu/items/resources/video-item';

import { Graphql } from '@shared/models/graphql';

import { MenuLink } from '@models/menu-link';

import './style.scss';

type LinkItemProps = {
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function VideoLinkItem({
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  const { i18n } = useTranslation();
  const decodedHash = isBrowser
    ? decodeURIComponent(slug(window.location.hash))
    : '';
  const data = VideoQuery();
  const videos = translateContent(data?.videos?.edges ?? [], i18n.language);

  return megaMenuStyle === 'modern' ? (
    <ModernVideoList
      videos={videos}
      menuLink={menuLink}
      decodedHash={decodedHash}
    />
  ) : (
    <DefaultVideoList
      videos={videos}
      menuLink={menuLink}
      decodedHash={decodedHash}
    />
  );
}

function ModernVideoList({
  videos,
  menuLink,
  decodedHash
}: {
  videos: Graphql[];
  menuLink: MenuLink;
  decodedHash: string;
}): ReactElement {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column mt-3">
      <p className="font-weight-normal text-secondary text-sm">
        {t('Mega Menu Videos overview')}
      </p>
      <div className="background-soft-grey-white p-4 d-none d-xl-block">
        {videos.slice(0, 2).map((video: Graphql, index: number) => (
          <div className="d-flex flex-column mb-3" key={video?.node?.id}>
            <VideoItem
              node={video.node as Graphql}
              open={decodedHash === slug(video?.node?.title as string)}
              menuLink={menuLink}
              megaMenuStyle="modern"
              index={index}
            />
          </div>
        ))}
      </div>
      <div className="background-soft-grey-white p-4 d-xl-none">
        {videos.slice(0, 1).map((video: Graphql, index: number) => (
          <div className="d-flex flex-column mb-3" key={video?.node?.id}>
            <VideoItem
              node={video.node as Graphql}
              open={decodedHash === slug(video?.node?.title as string)}
              menuLink={menuLink}
              megaMenuStyle="modern"
              index={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function DefaultVideoList({
  videos,
  menuLink,
  decodedHash
}: {
  videos: Graphql[];
  menuLink: MenuLink;
  decodedHash: string;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t('Mega Menu Videos overview')}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <Row className="videos-container">
        {videos.slice(0, 3).map((video: Graphql) => (
          <div className="col-4 px-0" key={video?.node?.id}>
            <VideoItem
              node={video.node as Graphql}
              open={decodedHash === slug(video?.node?.title as string)}
              menuLink={menuLink}
            />
          </div>
        ))}
      </Row>
    </div>
  );
}

function VideoQuery() {
  const nodes = useStaticQuery(graphql`
    query VideoQuery {
      videos: allNodeVideo(limit: 12, sort: { fields: created, order: DESC }) {
        edges {
          node {
            id
            title
            langcode
            link: field_external_link {
              uri
            }
            relationships {
              media: field_video {
                name
                relationships {
                  file: field_media_video_file {
                    src: localFile {
                      publicURL
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes;
}
