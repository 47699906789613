import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import ImageProvider from '@core/providers/image/image-provider';
import DomPurify from '@core/helpers/dom-purify';

import { MenuLink } from '@models/menu-link';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function MethodologyLinkItem({
  machineName,
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  return megaMenuStyle === 'modern' ? (
    <ModernMethodology machineName={machineName} />
  ) : (
    <DefaultMethodology machineName={machineName} />
  );
}

function ModernMethodology({
  machineName
}: {
  machineName: string;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column mt-3">
      <div className="d-flex align-items-center mb-2">
        <div className="mr-2">
          <ImageProvider
            fluid={false}
            className="mb-2 object-fit-contain"
            fileName="methodology.png"
            height="50"
            width="50"
          />
        </div>
        <p className="font-weight-normal text-secondary text-sm">
          {t(`Mega Menu ${machineName} overview`)}
        </p>
      </div>
    </div>
  );
}

function DefaultMethodology({
  machineName
}: {
  machineName: string;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <h6 className="mega-menu-title mb-4">{t('Some Technologies Used')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center w-100 px-0">
            <div className="w-25 mx-4">
              <ImageProvider fluid fileName="methodology.png" height="120" />
            </div>
            <div className="font-weight-normal text-secondary">
              <DomPurify text={t('Some Technologies Used Copy')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
