import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';

import DomPurify from '@core/helpers/dom-purify';
import truncate from '@core/helpers/truncate';
import translateContent from '@core/helpers/translation';
import GatsbyImageProvider from '@core/providers/image/gatsby-image-provider';

import { MenuLink } from '@models/menu-link';
import { Graphql } from '@models/graphql';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function SuccessStoriesLinkItem({
  machineName,
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  return megaMenuStyle === 'modern' ? (
    <ModernSuccessStoriesLinkItem
      machineName={machineName}
      menuLink={menuLink}
    />
  ) : (
    <DefaultSuccessStoriesLinkItem
      machineName={machineName}
      menuLink={menuLink}
    />
  );
}

function ModernSuccessStoriesLinkItem({
  machineName
}: LinkItemProps): ReactElement {
  const { t } = useTranslation();
  const successStories = getSuccessStories();

  return (
    <div className="mega-menu-item mt-2">
      <div className="border-bottom">
        <p className="text-sm font-weight-normal text-secondary">
          {t(`Mega Menu ${machineName} overview`)}
        </p>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row mx-0">
            {successStories.slice(0, 1).map(({ node }) => (
              <div className="col-12 mb-3" key={node?.id}>
                <div className="row">
                  <div className="col-12">
                    <GatsbyImageProvider
                      nodeImage={node?.relationships?.image}
                      altImage={node?.image?.alt || ''}
                      className="w-100"
                      enableGatsbyImage={false}
                    />
                    <GatsbyLink
                      to={node?.link?.uri || ''}
                      className="card-link text-dark-indigo"
                    >
                      <DomPurify text={node?.title ?? ''} />
                    </GatsbyLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function DefaultSuccessStoriesLinkItem({
  machineName,
  menuLink
}: LinkItemProps): ReactElement {
  const { t } = useTranslation();
  const successStories = getSuccessStories();

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="row mx-0">
            {successStories.slice(0, 6).map(({ node }) => (
              <div className="col-6 mb-3" key={node?.id}>
                <div className="row">
                  <div className="col-4 px-0">
                    <GatsbyImageProvider
                      nodeImage={node?.relationships?.image}
                      altImage={node?.image?.alt || ''}
                      className="rounded-lg-top solution-thumbnail w-100 object-fit-cover position-relative"
                      enableGatsbyImage={false}
                    />
                  </div>
                  <div className="col">
                    <GatsbyLink
                      to={node?.link?.uri || ''}
                      className="card-link text-dark-indigo"
                    >
                      <DomPurify text={truncate(node?.title as string, 40)} />
                    </GatsbyLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end align-bottom">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left pl-3"
            to={menuLink?.link?.alias || ''}
          >
            {t('See more link')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}

function getSuccessStories(): Graphql[] {
  const { i18n } = useTranslation();
  const data = useSuccessStoriesQuery();

  const successStories = translateContent(
    data?.successStories?.edges ?? [],
    i18n.language
  );

  return successStories.map((successStory: Graphql) => ({
    ...(successStory as unknown as object),
    flipped: false
  }));
}

function useSuccessStoriesQuery() {
  return useStaticQuery(graphql`
    query WorkSuccessStoriesQuery {
      successStories: allNodeSuccessStory(
        limit: 6
        filter: { field_weight: { gte: 0 }, field_featured: { eq: false } }
        sort: { fields: field_weight, order: DESC }
      ) {
        edges {
          node {
            id
            title
            langcode
            weight: field_weight
            featured: field_featured
            link: path {
              uri: alias
              langcode
            }
            image: field_image {
              alt
              title
            }
            relationships {
              image: field_image {
                name: filename
              }
            }
          }
        }
      }
    }
  `);
}
