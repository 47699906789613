import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Navbar, Nav, NavDropdown, ListGroup } from 'react-bootstrap';
import { Link as GatsbyLink } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTimes,
  faPlay,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import ContentComponent from '@core/layout/navigation-bar/mega-menu/content-helpers';
import { useSearch } from '@core/providers/search/search-provider';
import ImageProvider from '@core/providers/image/image-provider';
import SearchAutocomplete from '@core/layout/navigation-bar/mega-menu/search-autocomplete';
import { getPathFromInternalUrl } from '@core/helpers/link';

import { MenuLink } from '@models/menu-link';
import { LocationModel } from '@models/location';

import './desktop-menu.scss';
import { Graphql } from '@shared/models/graphql';

type DesktopMenuProps = {
  menu: MenuLink[];
  location: LocationModel;
  megaMenuStyle?: string;
  megaMenuBlocks?: Graphql[];
};

export default function ModernDesktopMenu({
  menu,
  location,
  megaMenuStyle,
  megaMenuBlocks
}: DesktopMenuProps): React.ReactElement {
  const [contentMenu, setContentMenu] = useState<MenuLink | null>(null);
  const [indexActive, setIndexActive] = useState<number>(-1);
  const { t } = useTranslation();
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIndexActive(-1);
        setContentMenu(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIndexActive(-1);
    setContentMenu(null);
  }, [location.pathname]);

  const isMenuActive = indexActive !== -1;

  const getMenuLink = (link: MenuLink, index: number) => {
    const handleClick = (event: React.MouseEvent) => {
      if ((event.target as HTMLElement).closest('.mega-menu-video')) {
        return;
      }
      if ((event.target as HTMLElement).closest('.dropdown-menu.show')) {
        event.preventDefault();
        return;
      }

      if (indexActive === index) {
        setIndexActive(-1);
        setContentMenu(null);
      } else {
        setIndexActive(index);
        if (link.children && link.children.length > 0) {
          setContentMenu(link.children[0]);
        } else {
          setContentMenu(null);
        }
      }
    };

    const matchingBlock = megaMenuBlocks?.find(
      (block) => block.relationships?.menuLink?.title === link?.title
    );

    if (link.children && link.children.length <= 0) {
      return (
        <GatsbyLink
          key={`${link.drupalId}`}
          className={`nav-link nav-item small ml-2 d-flex align-items-center ${
            indexActive === index ? 'active' : ''
          }`}
          to={link?.link?.alias || ''}
        >
          {link.title}
        </GatsbyLink>
      );
    }

    return (
      <NavDropdown
        onClick={handleClick}
        className={`nav-item dropdown small ml-2 d-flex align-items-center mega-menu-modern modern-nav-item ${
          indexActive === index ? 'active' : ''
        } ${indexActive} ${index} ${
          link.title === t('Resources') ? 'dropdown-xl' : ''
        }`}
        key={`${link.drupalId}`}
        title={link.title}
        id={`dropdown-${link.drupalId}`}
        show={indexActive === index}
      >
        <div className="container h-100">
          <div className="row my-4 w-100 h-100">
            <div className="col-12 w-100 d-flex flex-column menu-table">
              <div className="h-100 d-flex justify-content-start">
                {link.title === t('Services') && (
                  <div className="d-flex menu-table-cell flex-wrap border-right justify-content-center px-xl-2">
                    <div className="col-12">
                      <GatsbyLink
                        className="mt-auto"
                        to={link?.link?.alias || ''}
                      >
                        <h4 className="mega-menu-item-title text-marine">
                          {link.title}{' '}
                          <FontAwesomeIcon
                            icon={faArrowRight as IconProp}
                            size="sm"
                            className="ml-1 mega-menu-item-arrow d-none"
                          />
                        </h4>
                      </GatsbyLink>
                      <p className="text-sm font-weight-normal text-secondary m-0">
                        {t(`Mega Menu Services overview`)}
                      </p>
                    </div>
                    <div className="service-children d-flex flex-wrap">
                      {link?.children?.map(
                        (child, index) =>
                          child.title !== t('See All') &&
                          child.title?.trim() !== t('Areas of Expertise') && (
                            <div
                              key={`link-${child.title}-${index}`}
                              className="col-6 mb-3 d-flex justify-content-center align-items-center"
                            >
                              <div className="text-marine">
                                <ContentComponent
                                  machineName={child.title || ''}
                                  menuLink={child}
                                  megaMenuStyle={megaMenuStyle}
                                />
                                <GatsbyLink
                                  className="mt-auto"
                                  to={child?.link?.alias || ''}
                                >
                                  <h4 className="mt-3 mega-menu-item-title text-marine text-center">
                                    {child.title}{' '}
                                    <FontAwesomeIcon
                                      icon={faArrowRight as IconProp}
                                      size="sm"
                                      className="ml-1 mega-menu-item-arrow d-none"
                                    />
                                  </h4>
                                </GatsbyLink>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                )}
                {link?.children
                  ?.filter((child) => {
                    if (link.title === t('Services')) {
                      return child.title?.trim() === t('Areas of Expertise');
                    } else {
                      return (
                        child.title &&
                        child.title !== t('Methodologies') &&
                        child.title !== t('Videos')
                      );
                    }
                  })
                  .map((child, index, array) => (
                    <div
                      key={`link-${child.title}`}
                      className={`d-flex menu-table-cell ${
                        child.title?.trim() === t('Areas of Expertise')
                          ? 'px-xl-2'
                          : ''
                      }`}
                    >
                      <div
                        className={`h-100 d-flex flex-column w-100 ${
                          index === array.length - 1 ? '' : 'border-right'
                        }`}
                      >
                        <div className="w-100 background-white">
                          <div
                            className="px-3 text-marine"
                            key={`link-${child.title}`}
                          >
                            <GatsbyLink
                              className="mt-auto"
                              to={child?.link?.alias || ''}
                            >
                              <h4 className="mega-menu-item-title text-marine">
                                {child.title}{' '}
                                <FontAwesomeIcon
                                  icon={faArrowRight as IconProp}
                                  size="sm"
                                  className="ml-1 mega-menu-item-arrow d-none"
                                />
                              </h4>
                            </GatsbyLink>
                          </div>
                        </div>
                        <div className="px-3 h-100">
                          <ContentComponent
                            machineName={child.title || ''}
                            menuLink={child}
                            megaMenuStyle={megaMenuStyle}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                {link.title === t('Resources') && (
                  <div className="d-flex menu-table-cell flex-column border-left">
                    {link?.children?.map(
                      (child, index) =>
                        (child.title === t('Methodologies') ||
                          child.title === t('Videos')) && (
                          <div
                            key={`link-${child.title}-${index}`}
                            className="d-flex"
                          >
                            <div
                              className="px-3 text-marine"
                              key={`link-${child.title}`}
                            >
                              <GatsbyLink
                                className="mt-auto"
                                to={child?.link?.alias || ''}
                              >
                                <h4 className="mega-menu-item-title text-marine">
                                  {child.title}{' '}
                                  <FontAwesomeIcon
                                    icon={faArrowRight as IconProp}
                                    size="sm"
                                    className="ml-1 mega-menu-item-arrow d-none"
                                  />
                                </h4>
                              </GatsbyLink>
                              <ContentComponent
                                machineName={child.title || ''}
                                menuLink={child}
                                megaMenuStyle={megaMenuStyle}
                              />
                            </div>
                          </div>
                        )
                    )}
                  </div>
                )}
                {matchingBlock && (
                  <div className="d-flex menu-table-cell background-soft-grey-white">
                    <div className="px-3 pt-3 h-100 w-100 d-flex flex-column">
                      {matchingBlock?.relationships?.image && (
                        <ImageProvider
                          className="pt-1 pb-2 block-menu-image"
                          fileName={matchingBlock?.relationships?.image?.name}
                          alt={
                            matchingBlock?.relationships?.image?.attributes?.alt
                          }
                        />
                      )}
                      {matchingBlock.title && (
                        <h4 className="text-marine mt-2">
                          {matchingBlock.title}
                        </h4>
                      )}
                      {matchingBlock.subtitle && (
                        <p className="text-emperor-grey mt-2">
                          {matchingBlock.subtitle}
                        </p>
                      )}
                      {matchingBlock.link && matchingBlock.link.uri && (
                        <GatsbyLink
                          className="text-base mt-auto mb-1"
                          to={getPathFromInternalUrl(matchingBlock.link.uri)}
                        >
                          {matchingBlock.link.title}
                        </GatsbyLink>
                      )}
                    </div>
                  </div>
                )}{' '}
              </div>
            </div>
          </div>
        </div>
      </NavDropdown>
    );
  };

  if (menu.length <= 0) {
    return <></>;
  }

  return (
    <>
      {isMenuActive && (
        <div
          className={`desktop-menu-overlay ${isMenuActive ? 'active' : ''}`}
          onClick={() => {
            setIndexActive(-1);
            setContentMenu(null);
          }}
        />
      )}
      <div
        className="desktop-menu d-flex align-items-center position-relative"
        ref={menuRef}
      >
        <Navbar className="ml-auto navbar-menu">
          <Nav>{menu.map(getMenuLink)}</Nav>
        </Navbar>
        <SearchBar />
      </div>
    </>
  );
}

function SearchBar(): React.ReactElement {
  const { showSearch, setShowSearch } = useSearch();

  return (
    <>
      <span className="search-icon cursor-pointer">
        <FontAwesomeIcon
          icon={(showSearch ? faTimes : faSearch) as IconProp}
          onClick={() => setShowSearch(!showSearch)}
        />
      </span>
      {showSearch && <SearchAutocomplete limit={8} />}
    </>
  );
}
