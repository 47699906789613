import React, { useEffect, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleRight,
  faPlayCircle
} from '@fortawesome/free-solid-svg-icons';

import { slug } from '@core/helpers/strings';

import { MenuLink } from '@models/menu-link';
import { Graphql } from '@models/graphql';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type CardVideoProps = {
  node: Graphql;
  open: boolean;
  menuLink: MenuLink;
  megaMenuStyle?: string;
  index?: number;
};

export default function VideoItem({
  node,
  open,
  menuLink,
  megaMenuStyle,
  index
}: CardVideoProps): React.ReactElement {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  let isFile = false;
  let source: string | undefined;

  source = node?.link?.uri || undefined;

  if (node?.relationships?.media?.relationships?.file?.src?.url) {
    source = node.relationships.media.relationships.file.src.url;
    isFile = true;
  }

  if (!source) {
    return <></>;
  }

  useEffect(() => {
    setOpen(open);
  }, []);

  const getVideoComponent = () => {
    if (isFile) {
      return (
        <video width="100%" height="105px">
          <source src={`${source}#t=1`} />
          <track default kind="captions" srcLang="en" />
        </video>
      );
    }

    return megaMenuStyle === 'modern' ? (
      <ReactPlayer
        onClickPreview={() => setOpen(true)}
        light
        width="100%"
        height="125px"
        url={source}
      />
    ) : (
      <ReactPlayer
        onClickPreview={() => setOpen(true)}
        light
        width="100%"
        height="105px"
        url={source}
      />
    );
  };

  return (
    <>
      <Modal show={isOpen} onHide={() => setOpen(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{node.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center py-5">
          <iframe
            title={`${node.title}`}
            src={source}
            frameBorder="0"
            height="500"
            width="100%"
          />
        </Modal.Body>
      </Modal>

      <Col
        md={12}
        className="component card-video p-0"
        id={slug(node?.title || '')}
      >
        <div className={`w-100`}>
          <div className="video-thumbnail-wrapper">
            <div className="player-wrapper position-relative w-100 p-0">
              {megaMenuStyle !== 'modern' || index === 0 ? (
                <Button
                  variant="link"
                  onClick={() => setOpen(true)}
                  className="player-wrapper--icon w-100 h-100"
                >
                  <FontAwesomeIcon size="2x" icon={faPlayCircle as IconProp} />
                </Button>
              ) : null}
              {getVideoComponent()}
            </div>
          </div>
          <div className="description-container justify-content-between mt-1">
            {node?.title && (
              <GatsbyLink
                to={`${menuLink?.link?.alias}/#${slug(node?.title || '')}`}
                className="card-link text-marine"
              >
                <h6 className="mt-1 mb-0 p-0">{node?.title}</h6>
              </GatsbyLink>
            )}
            {megaMenuStyle !== 'modern' && (
              <div className="d-flex flex-wrap justify-content-between align-items-center">
                <a
                  onClick={() => setOpen(true)}
                  className="text-nice-blue text-bold p-0 text-decoration-none"
                  href={`${menuLink?.link?.alias}/#${slug(node?.title || '')}`}
                >
                  {t('See video')}
                  <FontAwesomeIcon
                    className="ml-1"
                    icon={faAngleDoubleRight as IconProp}
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </Col>
    </>
  );
}
