import { useStaticQuery, graphql } from 'gatsby';

export default function allNodeServiceQuery() {
  const nodeServices = useStaticQuery(graphql`
    query NodeServiceQuery {
      services: allNodeService(filter: {field_weight: {gte: 0}}, sort: {fields: field_weight, order: ASC}) {
        edges {
          node {
            id
            title
            langcode
            weight: field_weight
            wysiwyg: body {
              processed
              summary
            }
            link: path {
              uri: alias
              langcode
            }
            relationships {
              highlights: field_highlights {
                ...FeaturedViewContent
              }
              image: field_thumbnail {
                name

                attributes: field_media_image {
                  alt
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodeServices;
}
