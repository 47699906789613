import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import DomPurify from '@core/helpers/dom-purify';

import './styles.scss';

type LinkItemProps = {
  machineName: string;
  megaMenuStyle?: string;
};

export default function AteamLinkItem({
  machineName,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  return megaMenuStyle === 'modern' ? (
    <ModernAteamLinkItem machineName={machineName} />
  ) : (
    <DefaultAteamLinkItem machineName={machineName} />
  );
}
function ModernAteamLinkItem({
  machineName
}: {
  machineName: string;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mt-2">
      <p className="text-sm font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <div className="row company-content mt-3">
        <div className="col">
          <DomPurify text={t('Mega Menu Benefits overview')} />
        </div>
      </div>
    </div>
  );
}

function DefaultAteamLinkItem({
  machineName
}: {
  machineName: string;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="my-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <div className="row company-content">
        <div className="col">
          <DomPurify text={t('Mega Menu Benefits overview')} />
        </div>
        <div className="col">
          <DomPurify text={t('Mega Menu Our Selection Process overview')} />
        </div>
        <div className="d-flex align-items-end align-bottom">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left"
            to={t('Mega Menu A+ Team Apply overview')}
          >
            {t('Apply')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}
