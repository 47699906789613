// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';

import {
  buildRootnetPhoneFormat,
  extractRootnetPhoneNumber
} from '@core/services/rootnet-service';
import { parseJson } from '@core/helpers/strings';

import FormContext from '@modules/apply_form/utils/form-context';
import allNodeSelectListQuery from '@modules/apply_form/default-query';
import ErrorFormLabel from '@modules/apply_form/components/error-form-label';

import { GenericDataType } from '@models/generic-data-type';
import { File } from '@models/file';

const {
  fields,
  currentStepFieldId,
  cvFileFieldId,
  questions,
  generalQuestionsField
} = require('@modules/apply_form/config');

export default function Step1() {
  const { t } = useTranslation();
  const { countries } = allNodeSelectListQuery();

  const errorField = (fieldName: string) =>
    errors && errors[fieldName] ? 'border-danger' : '';

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const {
    submitFirstStep,
    workflowId,
    workflow,
    loading,
    getFieldFromName,
    getFieldCollectionByName,
    uploadFile
  } = React.useContext(FormContext);

  const onSubmit = async (data: object) => {
    const workflowMetas = [];
    let generalQuestions = '';

    questions.general.forEach((item: { question: string; fieldCollectionName: string }) => {
      const answer = data[item.fieldCollectionName.toLowerCase()];

      if (item.question && answer) {
        generalQuestions += `Question: ${item.question}<br>Answer: ${answer}<br><br>`;
    }
    });

    if (generalQuestions) {
      workflowMetas.push({
        workflow_type_field_id: generalQuestionsField,
        value: generalQuestions.trim()
      });
    }

    Object.keys(data).map((item) => {
      if (item) {
        workflowMetas.push({
          workflow_type_field_id: parseInt(item, 10),
          value: (data as any)[item]
        });
      }

      return null;
    });

    workflowMetas.push({
      workflow_type_field_id: currentStepFieldId,
      value: 1
    });

    const parsedData = {
      workflow_id: parseInt(workflowId, 10),
      workflow_metas: workflowMetas.filter(
        (meta) => !!meta.workflow_type_field_id
      )
    };

    submitFirstStep!(JSON.stringify(parsedData));
  };

  if (!workflow) {
    return <></>;
  }

  return (
    <div className="row">
      <div className="col-md-9">
        <div className="mt-4">
          <h2 className="text-center text-primary">
            {t('HI')} {(workflow as any)['title']}!
          </h2>
          <h2 className="text-center  apply-color-text font-weight-light">
            {t('Welcome you can apply now!')}
          </h2>
          <p className="text-secondary text-center font-weight-light mt-4">
            {t(
              'Fill out the following form to start your job application in the company, share your skills and experience so that we can learn a little more about you. With more than 10 years of foundation and over 300 international clients, Rootstack is one of the technology companies with the highest growth projection in Latin America.'
            )}
          </p>
        </div>
        <div className="col-12 mb-3">
          <h4 className="apply-color-text font-weight-bold mt-4 text-center">
            {t('General questions')}
          </h4>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">
                  {t('Country of Residence')}
                  <span className="text-danger"> *</span>
                </span>
                <select
                  {...register(
                    getFieldCollectionByName!('Location').id.toString(),
                    {
                      required: true
                    }
                  )}
                  className="form-control"
                  defaultValue={parseJson(getFieldFromName!('Location')?.value)}
                >
                  <option value="">{t('Select an option')}</option>
                  {countries.map(({ node }) => (
                    <option key={`${node?.crmId}`} value={`${node?.crmId}`}>
                      {node?.name}
                    </option>
                  ))}
                </select>
                <ErrorFormLabel
                  error={
                    errors[getFieldCollectionByName!('Location').id.toString()]
                  }
                  message={t('This field must be filled correctly')}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">
                  {t('Current salary')}
                  <span className="text-danger"> *</span>
                </span>
                <input
                  {...register(
                    getFieldCollectionByName!(
                      'Current salary range'
                    ).id.toString(),
                    { required: true }
                  )}
                  defaultValue={
                    getFieldFromName!('Current salary range')?.displayValue
                  }
                  type="number"
                  className="form-control"
                />
                <ErrorFormLabel
                  error={
                    errors[
                      getFieldCollectionByName!(
                        'Current salary range'
                      ).id.toString()
                    ]
                  }
                  message={t('This field must be filled correctly')}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">
                  {t('Salary aspiration')}
                  <span className="text-danger"> *</span>
                </span>
                <input
                  {...register(
                    getFieldCollectionByName!(
                      'Salary expectation'
                    ).id.toString(),
                    { required: true }
                  )}
                  defaultValue={
                    getFieldFromName!('Salary expectation')?.displayValue
                  }
                  type="number"
                  className="form-control"
                />
                <ErrorFormLabel
                  error={
                    errors[
                      getFieldCollectionByName!(
                        'Salary expectation'
                      ).id.toString()
                    ]
                  }
                  message={t('This field must be filled correctly')}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {questions.general.map((question: GenericDataType) => {
              const isTextArea = question.inputType === 'textarea';
              const colClasses = isTextArea
                ? 'col-12 col-sm-12'
                : 'col-12 col-sm-6';

              return (
                <div key={question.question} className={`${colClasses} mt-3`}>
                  <div className="form-group">
                    <span className="apply-color-text font-weight-bold">
                      {t(question.question)}
                      <span className="text-danger"> *</span>
                    </span>

                    {isTextArea ? (
                      <textarea
                        className="form-control"
                        {...register(
                          getFieldCollectionByName(
                            question.fieldCollectionName
                          )?.id.toString() || '',
                          { required: true, maxLength: 500 }
                        )}
                        rows={3}
                      />
                    ) : (
                      <select
                        className="form-control"
                        {...register(
                          getFieldCollectionByName(
                            question.fieldCollectionName
                          )?.id.toString() || '',
                          { required: true }
                        )}
                      >
                        {question.options?.map(
                          (option: string, index: number) => (
                            <option key={index} value={option === 'Select an option' ? '' : option}>
                              {t(option)}
                            </option>
                          )
                        )}
                      </select>
                    )}
                    <ErrorFormLabel
                      error={
                        errors[
                          getFieldCollectionByName(
                            question.fieldCollectionName
                          )?.id.toString() || ''
                        ]
                      }
                      message={t('This field must be filled correctly')}
                    />

                    {isTextArea && (
                      <small className="form-text text-muted text-right">
                        {t('(maximum 500 characters)')}
                      </small>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row"></div>
          <div className="row mb-3">
            <div className="offset-md-9 col-md-3 d-flex justify-content-center">
              <button
                disabled={loading}
                className="btn btn-primary btn-block"
                type="submit"
              >
                {t('Next')}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-md-1" />
      <div className="col-md-2">
        <div className="mt-5 mb-5 mt-md-0 mb-md-0">
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <div className="text-step1-apply mt-3 ">
                <h4 className="text-step1-apply">
                  {t('About')}
                  <br />
                  <strong>Rootstack</strong>
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <img src={require('@assets/images/beneficios_1.png')} alt="" />
              <div className="text-step1-apply mt-3 ">
                <p>
                  <strong>+10</strong> {t('Years of experience')}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <img src={require('@assets/images/beneficios_2.png')} alt="" />
              <div className="text-step1-apply mt-3 ">
                <p>
                  <strong>+90</strong> {t('Collaborators')}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <img src={require('@assets/images/beneficios_3.png')} alt="" />
              <div className="text-step1-apply mt-3 ">
                <p>
                  <strong>+9</strong> {t('Countries')}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <img src={require('@assets/images/beneficios_4.png')} alt="" />
              <div className="text-step1-apply mt-3 ">
                <p>
                  <strong>+6</strong> {t('Locations')}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <img
                src={require('@assets/images/step_1.png')}
                className="image-step1-apply"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ErrorFieldAlert({
  errors,
  fieldName
}: {
  errors: GenericDataType;
  fieldName: string;
}) {
  const { t } = useTranslation();

  return errors && errors[fieldName] ? (
    <span className="error text-danger">{t('Required field')}</span>
  ) : null;
}
