import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';

import translateContent from '@core/helpers/translation';
import GatsbyImageProvider from '@core/providers/image/gatsby-image-provider';

import { MenuLink } from '@models/menu-link';
import { Graphql } from '@models/graphql';

import './style.scss';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function ClientsLinkItem({
  machineName,
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  return megaMenuStyle === 'modern' ? (
    <ModernClientsLinkItem machineName={machineName} menuLink={menuLink} />
  ) : (
    <DefaultClientsLinkItem machineName={machineName} menuLink={menuLink} />
  );
}
function ModernClientsLinkItem({ machineName }: LinkItemProps) {
  const { t } = useTranslation();
  const clients = getClients();

  return (
    <div className="mega-menu-item mt-2">
      <div className="border-bottom mb-2">
        <p className="text-sm font-weight-normal text-secondary">
          {t(`Mega Menu ${machineName} overview`)}
        </p>
      </div>
      <div className="clients-image-container w-100 d-flex flex-wrap align-items-center justify-content-center p-2">
        {clients.slice(0, 6).map(({ node }) => (
          <div className="col-6 p-2" key={node?.id}>
            <GatsbyImageProvider
              nodeImage={node?.relationships?.image}
              altImage={node?.image?.alt || ''}
              className="w-100 object-fit-contain clients-image"
              enableGatsbyImage={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function DefaultClientsLinkItem({ machineName, menuLink }: LinkItemProps) {
  const { t } = useTranslation();
  const clients = getClients();

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="row mx-0">
            {clients.slice(0, 6).map(({ node }) => (
              <div className="col-4 mb-3 px-0" key={node?.id}>
                <GatsbyImageProvider
                  nodeImage={node?.relationships?.image}
                  altImage={node?.image?.alt || ''}
                  className="work-thumbnail-clients h-100 object-fit-cover position-relative"
                  enableGatsbyImage={false}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end align-bottom">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left pl-3"
            to={menuLink?.link?.alias || ''}
          >
            {t('See more link')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}

function getClients(): Graphql[] {
  const { i18n } = useTranslation();
  const data = useClientsQuery();
  const clients = translateContent(data?.clients?.edges ?? [], i18n.language);

  return clients.map((client: Graphql) => ({
    ...(client as unknown as object),
    flipped: false
  }));
}

function useClientsQuery() {
  return useStaticQuery(graphql`
    query WorkClientsQuery {
      clients: allNodeClients(
        limit: 12
        filter: { field_weight: { gte: 0 } }
        sort: { fields: field_weight, order: DESC }
      ) {
        edges {
          node {
            id
            title
            langcode
            weight: field_weight
            link: field_link {
              title
              uri
            }
            relationships {
              image: field_image {
                name: filename
              }
            }
            image: field_image {
              alt
              title
            }
          }
        }
      }
    }
  `);
}
