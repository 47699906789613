import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';

import translateContent from '@core/helpers/translation';
import ImageProvider from '@core/providers/image/image-provider';

import { MenuLink } from '@models/menu-link';

import './style.scss';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function TechnologyLinkItem({
  machineName,
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  const { technologies } = TechnologiesQuery();
  const { t, i18n } = useTranslation();
  const items = translateContent(technologies?.edges ?? [], i18n.language);

  return megaMenuStyle === 'modern' ? (
    <ModernTechnologyLinkItem items={items} machineName={machineName} />
  ) : (
    <DefaultTechnologyLinkItem
      items={items}
      menuLink={menuLink}
      machineName={machineName}
    />
  );
}

function ModernTechnologyLinkItem({
  items,
  machineName
}: {
  items: any[];
  machineName: string;
}): ReactElement {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column mt-2">
      <p className="text-sm font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      {items.slice(0, 8).map(({ node }) => (
        <div className="d-flex flex-row align-items-center mb-3" key={node?.id}>
          <div className="mr-2">
            <ImageProvider
              fluid={false}
              fileName={node?.relationships?.menuIcon?.name}
              alt={node?.relationships?.menuIcon?.attributes?.alt}
              title={node?.relationships?.menuIcon?.attributes?.title}
              height="35"
              width="35"
            />
          </div>
          <GatsbyLink
            to={node?.link?.uri || ''}
            className="card-link text-marine"
          >
            <h6 className="m-0">{node?.title}</h6>
          </GatsbyLink>
        </div>
      ))}
    </div>
  );
}

function DefaultTechnologyLinkItem({
  items,
  menuLink,
  machineName
}: {
  items: any[];
  menuLink: MenuLink;
  machineName: string;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="row px-0">
            {items.slice(0, 6).map(({ node }) => (
              <div className="col-4 mb-3 px-4" key={node?.id}>
                <ImageProvider
                  fileName={node?.relationships?.image?.name}
                  alt={node?.relationships?.image?.attributes?.alt}
                  title={node?.relationships?.image?.attributes?.title}
                  className="technology-thumbnail w-100 object-fit-cover position-relative"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end align-bottom">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left pl-3"
            to={menuLink?.link?.alias || ''}
          >
            {t('See more link')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}

function TechnologiesQuery() {
  const nodes = useStaticQuery(graphql`
    query TechnologiesQuery {
      technologies: allNodeTechnology(
        filter: { field_weight: { gte: 10 } }
        sort: { fields: field_weight, order: DESC }
      ) {
        edges {
          node {
            id
            title
            langcode
            body {
              processed
              summary
            }
            link: path {
              uri: alias
            }
            relationships {
              image: field_thumbnail {
                name
                attributes: field_media_image {
                  alt
                  title
                }
              }
              menuIcon: field_menu_icon {
                name
                attributes: field_media_image {
                  alt
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes;
}
