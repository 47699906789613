import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';

import translateContent from '@core/helpers/translation';
import ImageProvider from '@core/providers/image/image-provider';
import truncate from '@core/helpers/truncate';
import DomPurify from '@core/helpers/dom-purify';

import { MenuLink } from '@models/menu-link';

import './style.scss';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function AreaExpertiseLinkItem({
  machineName,
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  const { expertiseAreas } = ExpertiseAreasQuery();
  const { t, i18n } = useTranslation();
  const items = translateContent(expertiseAreas?.edges ?? [], i18n.language);

  return megaMenuStyle === 'modern' ? (
    <ModernAreaExpertiseLinkItem items={items} machineName={machineName} />
  ) : (
    <DefaulAreaExpertiseLinkItem
      items={items}
      menuLink={menuLink}
      machineName={machineName}
    />
  );
}

function ModernAreaExpertiseLinkItem({
  items,
  machineName
}: {
  items: any[];
  machineName: string;
}): ReactElement {
  const { t } = useTranslation();
  const reducedItems = items.slice(0, 16);
  return (
    <div className="d-flex flex-column mt-2 h-100">
      <p className="text-sm font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName.trim()} overview`)}
      </p>
      <div className="pb-5 h-100 d-flex flex-wrap">
        <div className="border-right pr-3">
          {reducedItems
            .slice(0, Math.ceil(reducedItems.length / 2))
            .map(({ node }) => (
              <div
                className="d-flex flex-row align-items-center mb-3"
                key={node?.id}
              >
                <ImageProvider
                  fluid={false}
                  fileName={
                    node?.relationships?.menuIcon?.name ||
                    node?.relationships?.image?.name
                  }
                  alt={
                    node?.relationships?.menuIcon?.attributes?.alt ||
                    node?.relationships?.image?.name
                  }
                  title={
                    node?.relationships?.menuIcon?.attributes?.title ||
                    node?.relationships?.image?.name
                  }
                  height="35"
                  className="mr-2"
                  width="35"
                />
                <GatsbyLink
                  to={node?.link?.uri || ''}
                  className="card-link text-marine"
                >
                  <div className="d-none d-xl-block">
                    <DomPurify text={truncate(node?.title as string, 23)} />
                  </div>
                  <div className="d-xl-none">
                    <DomPurify text={truncate(node?.title as string, 15)} />
                  </div>
                </GatsbyLink>
              </div>
            ))}
        </div>
        <div className="pl-3">
          {reducedItems
            .slice(Math.ceil(reducedItems.length / 2))
            .map(({ node }) => (
              <div
                className="d-flex flex-row align-items-center mb-3"
                key={node?.id}
              >
                <ImageProvider
                  fluid={false}
                  fileName={
                    node?.relationships?.menuIcon?.name ||
                    node?.relationships?.image?.name
                  }
                  alt={
                    node?.relationships?.menuIcon?.attributes?.alt ||
                    node?.relationships?.image?.name
                  }
                  title={
                    node?.relationships?.menuIcon?.attributes?.title ||
                    node?.relationships?.image?.name
                  }
                  height="35"
                  className="mr-2"
                  width="35"
                />
                <GatsbyLink
                  to={node?.link?.uri || ''}
                  className="card-link text-marine"
                >
                  <div className="d-none d-xl-block">
                    <DomPurify text={truncate(node?.title as string, 23)} />
                  </div>
                  <div className="d-xl-none">
                    <DomPurify text={truncate(node?.title as string, 15)} />
                  </div>
                </GatsbyLink>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

function DefaulAreaExpertiseLinkItem({
  items,
  menuLink,
  machineName
}: {
  items: any[];
  menuLink: MenuLink;
  machineName: string;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mega-menu-item pt-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName.trim()} overview`)}
      </p>
      <h6 className="mega-menu-title">{t('Mega Menu The Last')}</h6>
      <div className="row">
        <div className="col-12">
          <div className="row mx-0">
            {items.slice(0, 6).map(({ node }) => (
              <div className="col-6 mb-2" key={node?.id}>
                <div className="row mt-2">
                  <div className="col-2 pb-1">
                    <ImageProvider
                      fileName={node?.relationships?.image?.name}
                      alt={node?.relationships?.image?.attributes?.alt}
                      title={node?.relationships?.image?.attributes?.title}
                      height="48"
                    />
                  </div>
                  <div className="col pt-1">
                    <GatsbyLink
                      to={node?.link?.uri || ''}
                      className="card-link text-dark-indigo"
                    >
                      {node?.title}
                    </GatsbyLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end align-bottom">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left pl-3"
            to={menuLink?.link?.alias || ''}
          >
            {t('See more link')}
            {' >>'}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}

function ExpertiseAreasQuery() {
  const nodes = useStaticQuery(graphql`
    query ExpertiseAreasQuery {
      expertiseAreas: allNodeExpertiseArea(
        sort: { fields: field_weight, order: DESC }
      ) {
        edges {
          node {
            id
            title
            langcode
            wysiwyg: body {
              summary
            }
            link: path {
              uri: alias
            }
            relationships {
              image: field_thumbnail {
                name
                attributes: field_media_image {
                  alt
                  title
                }
              }
              menuIcon: field_menu_icon {
                name
                attributes: field_media_image {
                  alt
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes;
}
