import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link as GatsbyLink } from 'gatsby';

import { isBrowser } from '@core/helpers/ssr';

import { Graphql } from '@models/graphql';
import { Translation } from '@models/translation';
import { Link } from '@models/link';
import { useMobileL } from '@core/helpers/responsive';
import { useTranslation } from 'react-i18next';

const { languages } = require('@config/languages');

export default function TranslationSwitcher({ node }: Graphql): React.ReactElement {
  if (!node) {
    return (<></>);
  }

  const { i18n } = useTranslation();
  const translationOrder = languages;
  const isMobileL = useMobileL();

  let translations = node?.relationships?.translations || [];

  const currentTranslation: Translation = {
    path: node.path as Link,
    id: node.id || '',
    langcode: node?.langcode || process.env.GATSBY_DEFAULT_LANG || 'en'
  };

  translations.unshift(currentTranslation);

  translations = Array.from(new Set(translations.map((translation) => translation.langcode)))
    .map((langcode) => translations
      .find((translation) => translation.langcode === langcode)) as Translation[];

  translations = translations.sort((a, b) => (
    translationOrder.indexOf(a.langcode) - translationOrder.indexOf(b.langcode)
  ));

  const getPath = (translation: Translation) => {
    if (isBrowser) {
      return `${translation?.path?.alias}${window.location.search}`;
    }

    return translation?.path?.alias || '';
  };

  return (
    <Nav>
      {translations.map((translation: Translation, index: number) => {
        const className = translation?.langcode === node?.langcode ? 'active-lang-option' : 'inactive-lang-option';
        const separator = isMobileL ? '-' : '/';
        return (
          <GatsbyLink
            key={translation.id}
            className="nav-link lang-switch text-uppercase small px-0"
            to={getPath(translation)}
            onClick={() => i18n.changeLanguage(translation.langcode)}
          >
            <span className={className}>
              {(translation.langcode)}
            </span>
            {index + 1 < translations.length ? separator : ''}
          </GatsbyLink>
        );
      })}
    </Nav>
  );
}
