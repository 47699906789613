import React, { ReactElement, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import DomPurify from '@core/helpers/dom-purify';
import ImageProvider from '@core/providers/image/image-provider';

import { MenuLink } from '@models/menu-link';

import './styles.scss';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function AboutUsLinkItem({
  machineName,
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  return megaMenuStyle === 'modern' ? (
    <ModernAboutUsLinkItem machineName={machineName} menuLink={menuLink} />
  ) : (
    <DefaultAboutUsLinkItem machineName={machineName} menuLink={menuLink} />
  );
}

function ModernAboutUsLinkItem({ machineName, menuLink }: LinkItemProps): ReactElement {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const videoUrl = `${process.env.GATSBY_BASE_URL_API}${t('Mega Menu About Us Video')}`;
  const previewImageUrl = t('Mega Menu About Us Video Preview');

  return (
    <div className="mt-2">
      <div className="mb-3">
        <p className="text-sm font-weight-normal text-secondary">
          {t(`Mega Menu ${machineName} overview`)}
        </p>
      </div>
      <div className="mega-menu-video" onClick={(e) => e.stopPropagation()}>
        <div 
          className="position-relative cursor-pointer w-100" 
          onClick={() => setOpen(true)}
        >
          <ImageProvider
            fileName={previewImageUrl}
            alt="Video Preview"
            title="Video Preview"
            className="h-100 object-fit-cover rounded"
          />
          <div className="position-absolute mega-menu-play-icon">
            <ImageProvider
              fileName="play-icon.png"
              alt="Play Icon"
              title="Play Icon"
              fluid={false}
              width="50"
              height="50"
            />
          </div>
        </div>
      </div>
      <Modal show={isOpen} onHide={() => setOpen(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('Meet Rootstack Team')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center py-5">
          <ReactPlayer
            url={videoUrl}
            width="100%"
            height="500px"
            controls={true}
            playing={true}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

function DefaultAboutUsLinkItem({
  machineName,
  menuLink
}: LinkItemProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div>
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <div className="row company-content">
        <div className="col">
          <DomPurify text={t('Mega Menu About Us Content')} />
        </div>
        <div className="d-flex align-items-end align-bottom w-25">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left"
            to={menuLink?.link?.alias || ''}
          >
            {t('Mega Menu About Us Link')}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}
