import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import DomPurify from '@core/helpers/dom-purify';
import ImageProvider from '@core/providers/image/image-provider';

import { MenuLink } from '@models/menu-link';

import './styles.scss';

type LinkItemProps = {
  machineName: string;
  menuLink: MenuLink;
  megaMenuStyle?: string;
};

export default function TalentLinkItem({
  machineName,
  menuLink,
  megaMenuStyle
}: LinkItemProps): ReactElement {
  return megaMenuStyle === 'modern' ? (
    <ModernTalentLinkItem machineName={machineName} menuLink={menuLink} />
  ) : (
    <DefaultTalentLinkItem machineName={machineName} menuLink={menuLink} />
  );
}
function ModernTalentLinkItem({ machineName }: LinkItemProps): ReactElement {
  const { t } = useTranslation();
  const ourPerks = [
    {
      id: 1,
      label: `${t('Mega Menu Talent Perk 1')}`,
      icon: 'perk-1.svg'
    },
    {
      id: 2,
      label: `${t('Mega Menu Talent Perk 2')}`,
      icon: 'perk-2.svg'
    },
    {
      id: 3,
      label: `${t('Mega Menu Talent Perk 3')}`,
      icon: 'perk-3.svg'
    }
  ];

  return (
    <div className="mt-2">
      <p className="text-sm font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <h6 className="text-marine mb-3">{t('Our Perks')}</h6>
      <div className="mega-menu-our-perks">
        {ourPerks.map((perk) => (
          <div
            className="our-perk d-flex align-items-center mb-2"
            key={perk.id}
          >
            <div className="mr-2">
              <ImageProvider
                fluid={false}
                fileName={perk.icon}
                alt={perk.label}
                title={perk.label}
                height="35"
                width="35"
              />
            </div>
            <p className="mb-0 text-sm font-weight-normal text-secondary">
              {perk.label}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

function DefaultTalentLinkItem({
  machineName,
  menuLink
}: LinkItemProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="py-4">
      <p className="font-weight-normal text-secondary">
        {t(`Mega Menu ${machineName} overview`)}
      </p>
      <div className="row company-content">
        <div className="col">
          <DomPurify text={t('Mega Menu Talent Content')} />
        </div>
        <div className="d-flex align-items-end align-bottom w-25">
          <GatsbyLink
            className="small font-weight-bold text-cool-blue text-sm-left"
            to={menuLink?.link?.alias || ''}
          >
            {t('Mega Menu Talent Link')}
          </GatsbyLink>
        </div>
      </div>
    </div>
  );
}
